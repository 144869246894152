@import 'node_modules/@bswing/uhc-core/dist/scss/mixins';
@import 'node_modules/@bswing/uhc-core/dist/scss/variables';

.uhc-badge {
  background: $color-blue-secondary;
  border-radius: 100%;
  color: $color-white;
  font-size: 12px;
  font-weight: bold;
  line-height: 16px;
  position: absolute;
  top: -2px;
  right: -2px;
  text-align: center;
  width: 16px;
  height: 16px;
}
