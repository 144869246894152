@import 'node_modules/@bswing/uhc-core/dist/scss/mixins';
@import 'node_modules/@bswing/uhc-core/dist/scss/variables';

$mobile-breakpoint: 1000px;

.uhc-tabs__container {
  @include media-query($mobile-breakpoint) {
    overflow-y: hidden;
  }
}

.uhc-tabs__border {
  @include media-query($mobile-breakpoint) {
    border-bottom: $border-base;
  }
}

.uhc-tabs {
  display: flex;
  flex-direction: column;

  @include media-query($mobile-breakpoint) {
    flex-direction: row;
    height: 140px;
  }

  nav {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 2px;

    @include media-query($mobile-breakpoint) {
      align-items: flex-end;
      flex-direction: row;
      gap: 0;
    }
  }

  .uhc-tabs__tab {
    align-items: center;
    background: $color-gray-2;
    color: $color-blue-secondary;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex: 1;
    font-size: 14px;
    font-weight: bold;
    padding: 20px;
    position: relative;
    text-decoration: none;
    transition: all ease 200ms;

    @include media-query($mobile-breakpoint) {
      align-items: initial;
      color: $color-gray-8;
      flex-direction: column;
      font-size: 16px;
      font-weight: normal;
      height: 130px;
      padding: 8px 20px;

      &:not(.uhc-tabs__tab--active):not(:last-child):not(:focus)::after {
        content: '';
        display: block;
        width: 1px;
        background: $color-gray-5;
        border-left: 1px solid $color-white;
        position: absolute;
        right: 0;
        top: 10px;
        bottom: 10px;
        z-index: 0;
      }
    }

    &.uhc-tabs__first-tab {
      background: none;
      color: $color-gray-8;
      font-size: 16px;
      font-weight: normal;
      flex: 0;

      @include media-query($mobile-breakpoint) {
        background: $color-gray-2;
        border-radius: 10px 0 0 0;
        justify-content: center;
        font-size: 14px;
        min-width: 125px;
      }
    }

    &:last-child {
      @include media-query($mobile-breakpoint) {
        border-radius: 0 10px 0 0;
      }
    }

    &:focus {
      outline: none;
    }

    &:focus-visible {
      outline: 3px solid $color-blue-secondary;
      outline-offset: -3px;
      position: relative;
    }

    .uhc-tabs__tab-icon {
      display: none;

      @include media-query($mobile-breakpoint) {
        display: block;
        margin-bottom: 5px;
        width: 45px;
        height: 45px;
        transition: all ease 200ms;
      }
    }

    .uhc-tabs__tab-mobile-plan-count {
      color: $color-blue-primary;
      display: block;
      margin-right: 15px;

      @include media-query($mobile-breakpoint) {
        display: none;
      }
    }

    .uhc-tabs__tab-mobile-arrow {
      display: block;
      margin-left: 10px;

      @include media-query($mobile-breakpoint) {
        display: none;
      }
    }

    .uhc-tabs__tab-desktop-plan-count {
      display: none;

      @include media-query($mobile-breakpoint) {
        color: $color-blue-secondary;
        display: block;
        font-size: 14px;
        margin-top: 5px;
      }
    }
  }

  .uhc-tabs__tab--active {
    background: $color-blue-secondary;
    color: $color-white;
    font-size: 14px;
    font-weight: bold;
    position: relative;
    z-index: 2;

    .uhc-tabs__tab-mobile-plan-count {
      color: $color-white;
    }

    .uhc-tabs__tab-icon {
      width: 55px;
      height: 55px;
    }

    @include media-query($mobile-breakpoint) {
      background: $color-white !important;
      border-top: $border-base;
      border-right: $border-base;
      border-left: $border-base;
      border-radius: 10px 10px 0 0 !important;
      box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.15);
      color: $color-blue-primary;
      font-size: 16px;
      height: 140px;

      &:after {
        content: '';
        background: $color-white;
        display: block;
        height: 1px;
        width: 100%;
        position: absolute;
        right: 0;
        bottom: -1px;
        left: 0;
        z-index: 3;
      }
    }
  }
}
