@import 'node_modules/@bswing/uhc-core/dist/scss/mixins';
@import 'node_modules/@bswing/uhc-core/dist/scss/variables';

.uhc-toast {
  border-radius: 8px;
  background-color: #fef9ea;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  pointer-events: auto;
  position: relative;
  overflow: hidden;
  outline: 1px solid rgba(0, 0, 0, 0.05);
  outline-offset: -1px;
  max-width: 650px;
  width: 100%;

  &:before {
    content: '';
    background: $color-gold-primary;
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 6px;
  }

  .uhc-toast__close {
    appearance: none;
    border: none;
    background: none;
    cursor: pointer;
    display: block;
    padding: 5px;
    position: absolute;
    top: 15px;
    right: 15px;

    svg {
      display: block;
    }
  }
}

@keyframes toast-enter {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes toast-leave {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  to {
    opacity: 0;
    transform: scale(0.9);
  }
}

.uhc-toast--enter {
  animation: toast-enter 0.2s ease-out;
}

.uhc-toast--leave {
  animation: toast-leave 0.15s ease-in forwards;
}
