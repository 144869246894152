@import 'node_modules/@bswing/uhc-core/dist/scss/mixins';
@import 'node_modules/@bswing/uhc-core/dist/scss/variables';

.uhc-auto-enroll {
  background: $color-orange-primary;
  border-radius: 8px;
  color: $color-white;
  font-size: 15px;
  font-weight: bold;
  line-height: 18px;
  margin: 0 0 20px;
  max-width: fit-content;
  padding: 10px 20px;
  position: relative;

  a {
    color: $color-white;
    margin-left: 15px;
    text-decoration: underline;
  }

  .uhc-auto-enroll__arrow {
    position: absolute;
    bottom: -5px;
    left: 10%;
    width: 10px;
    height: 10px;
    background-color: $color-orange-primary;
    transform: rotate(-45deg);
  }
}
