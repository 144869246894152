.filter-drop__options {
    padding: 0 24px 24px;
}

.filter-drop {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 271px;
    padding-top: 24px;
    padding-bottom: 16px;
    background: #fff;
    border-radius: 4px;
    border: 1px solid var(--uhc-neutrals-gray-3, #E5E5E6);
    box-shadow: 0px 2px 12px 3px rgba(0, 0, 0, 0.12);
    z-index: 99;

    &.isActive {
        display: block;
    }
}

.filter-drop__heading {
    padding-inline: 24px;
    margin-bottom: 20px;
    font-size: 17px;
    font-weight: 700;
    color: #212223;
}

.filter-drop__footer {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #E5E5E6;
    padding-top: 16px;
    padding-inline: 24px;
}

.filter-button-drop__selection,
.filter-button-drop__plus {
    font-weight: 700;
}

.filter-button-drop__plus {
    margin-left: 5px;
}