@import 'node_modules/@bswing/uhc-core/dist/scss/mixins';
@import 'node_modules/@bswing/uhc-core/dist/scss/variables';

.uhc-sp-banner {
  background: $color-white;
  border: 1px solid $color-gray-4;
  border-radius: 8px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 26px 20px 20px;
  position: relative;
  width: 100%;
  z-index: 1;

  @include media-query {
    flex-direction: row;
    align-items: center;
    padding: 46px 40px 40px;
  }

  &:before {
    content: '';
    background: $color-gold-primary;
    display: block;
    height: 6px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  .uhc-sp-banner__left {
    border-bottom: 1px solid $color-blue-primary;
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 20px;
    padding-bottom: 20px;

    @include media-query {
      border-bottom: none;
      border-right: 1px solid $color-blue-primary;
      gap: 25px;
      margin-bottom: 0;
      padding-right: 100px;
      padding-bottom: 0;
    }
  }

  .uhc-sp-banner__right {
    @include media-query {
      margin-left: 40px;
    }
  }

  .uhc-sp-banner__icon {
    height: 64px;
    width: 64px;
  }
}
