@import 'node_modules/@bswing/uhc-core/dist/scss/mixins';
@import 'node_modules/@bswing/uhc-core/dist/scss/variables';

.uhc-empty-state {
  border: 2px solid $color-gray-5;
  border-radius: 8px;
  padding: 10px;

  @include media-query {
    border-radius: 20px;
    padding: 20px;
  }

  .uhc-empty-state__content {
    border: 2px dashed $color-gray-5;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 30px;
    text-align: center;
    width: 100%;

    @include media-query {
      padding: 50px;
    }
  }
}
