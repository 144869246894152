@import 'node_modules/@bswing/uhc-core/dist/scss/mixins';
@import 'node_modules/@bswing/uhc-core/dist/scss/variables';

$header-breakpoint: 1000px;

.uhc-header {
  .uhc-header__skip-link {
    background: $color-white;
    color: $color-blue-primary;
    clip: rect(0, 0, 0, 0);
    display: inline-block;
    font-size: 14px;
    font-weight: bold;
    min-height: 15px;
    min-width: 80px;
    padding-left: 5px;
    padding-right: 5px;
    position: absolute;
    left: 16px;
    text-decoration: none;
    transition: transform 0.3s ease;

    &:focus {
      clip: auto;
      opacity: 1;
      outline: 2px solid $color-teal-primary;
      outline-offset: 2px;
      transform: translateY(16px);
      z-index: 1;
    }

    &:hover {
      text-decoration: underline;
    }
  }

  .uhc-header__top {
    display: none;

    @include media-query($header-breakpoint) {
      background: $color-teal-quaternary;
      display: flex;
      align-items: center;
      height: 50px;
    }
  }

  .uhc-header__main {
    background: $color-white;
    display: flex;
    align-items: center;
    height: 64px;

    @include media-query($header-breakpoint) {
      height: 115px;
    }
  }

  .uhc-header__bottom {
    display: flex;
    align-items: center;
    background: $color-blue-primary;
    height: 42px;

    @include media-query($header-breakpoint) {
      height: 56px;
    }
  }

  .uhc-header__tabs {
    display: flex;
    height: 50px;
    align-items: stretch;
    list-style: none;
    padding: 0;
    margin: 0;

    .uhc-header__tab {
      display: flex;
      align-items: center;
      position: relative;

      &.uhc-header__tab--active {
        background: $color-white;

        &:before {
          background: $color-blue-primary;
          content: '';
          display: block;
          height: 4px;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
        }
      }

      a {
        color: $color-blue-primary;
        display: block;
        font-weight: bold;
        height: 100%;
        line-height: 50px;
        padding: 0 30px;
        text-decoration: none;

        &:focus {
          outline-offset: -3px;
        }
      }
    }
  }

  .uhc-header__search-form {
    position: relative;

    button {
      appearance: none;
      background: none;
      border: none;
      cursor: pointer;
      font-size: 0;
      padding: 0;
      position: absolute;
      top: 50%;
      margin-top: -12px;
      right: 10px;
      height: 24px;
      width: 24px;

      &:focus {
        outline: 2px solid $color-teal-primary;
        outline-offset: 2px;
      }
    }

    input {
      appearance: none;
      background: $color-white;
      border: 2px solid $color-blue-primary;
      border-radius: 100px;
      color: $color-gray-6;
      font-size: 15px;
      padding: 0 15px;
      height: 42px;
      width: 240px;

      &:focus {
        outline: none;
      }
    }

    input[type='search']::-ms-clear {
      display: none;
      width: 0;
      height: 0;
    }

    input[type='search']::-ms-reveal {
      display: none;
      width: 0;
      height: 0;
    }

    input[type='search']::-webkit-search-decoration,
    input[type='search']::-webkit-search-cancel-button,
    input[type='search']::-webkit-search-results-button,
    input[type='search']::-webkit-search-results-decoration {
      display: none;
    }

    ::-webkit-input-placeholder {
      font-style: italic;
    }
    :-moz-placeholder {
      font-style: italic;
    }
    ::-moz-placeholder {
      font-style: italic;
    }
    :-ms-input-placeholder {
      font-style: italic;
    }
  }

  .uhc-header__logo {
    flex-shrink: 1;

    &:focus {
      outline: 2px solid $color-blue-primary;
      outline-offset: 2px;
    }

    img {
      max-width: 100%;
      height: auto;
      width: 24px;

      @include media-query($header-breakpoint) {
        width: 38px;
      }
    }
  }

  .uhc-header__logo-text {
    display: none;

    @include media-query($header-breakpoint) {
      color: $color-blue-primary;
      display: block;
      font-size: 14px;
    }
  }

  .uhc-header__mobile {
    display: flex;
    align-items: center;

    @include media-query($header-breakpoint) {
      display: none;
    }
  }

  .uhc-header__desktop {
    display: none;

    @include media-query($header-breakpoint) {
      display: flex;
      align-items: center;
    }
  }

  .uhc-header__mobile-nav {
    align-items: center;
    display: flex;
    flex-direction: row;
    list-style: none;
    margin: 0;
    padding: 0;
    white-space: nowrap;

    > li {
      margin-left: $spacing-large;

      > a {
        text-decoration: none;
      }
    }
  }

  .uhc-header__saved-items-button {
    appearance: none;
    background: none;
    border: 2px solid $color-white;
    border-radius: 21px;
    color: $color-white;
    cursor: pointer;
    display: inline-block;
    font-family: $font-family-primary;
    font-size: 14px;
    font-weight: 700;
    height: 42px;
    line-height: 38px;
    max-width: 100%;
    overflow: initial;
    padding: 0 $spacing-base;
    position: relative;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    white-space: nowrap;

    &:focus-visible {
      outline: 2px solid $color-white;
      outline-offset: 2px;
    }
  }
}
