.checkbox__radio {
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: 8px;
  user-select: none;
  -webkit-tap-highlight-color: transparent;

  .checkbox__input {
    clip: rect(0 0 0 0); 
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap; 
    width: 1px;
  }

  .checkbox__label {
    display: block;
    font-size: 16px;
    font-weight: 500;
    padding-top: 3px;
  }

  .checkbox__visual {
    align-items: center;
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    width: 26px;
    height: 26px;
    background-color: #fff;
    border: 1px solid #7D7F81;
    border-radius: 26px;
  }

  .checkbox__input:checked ~ .checkbox__visual {
      background-color: #fff;
      border: 2px solid #196ECF;
      background-image: none;
      background-repeat: no-repeat;
      background-position: center;

      &:before {
        content: '';
        display: block;
        width: 14px;
        height: 14px;
        background-color: #196ECF;
        border-radius: 22px;
      }
  }
}

.checkbox + .checkbox {
    margin-top: 16px;
}
