@import 'node_modules/@bswing/uhc-core/dist/scss/mixins';
@import 'node_modules/@bswing/uhc-core/dist/scss/variables';

.uhc-filter-group {
  background-color: $color-white;
  width: 100%;

  &.uhc-filter-group--expanded {
    .uhc-filter-group__arrow {
      transform: scaleY(-1);
    }
  }

  .uhc-filter-group__header {
    align-items: center;
    appearance: none;
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    margin: 0;
    user-select: none;
    width: 100%;

    &:focus {
      outline: 3px solid $color-blue-secondary;
      outline-offset: $spacing-small;
    }
  }

  .uhc-filter-group__arrow {
    align-self: flex-end;
    flex-shrink: 0;
    margin: 0 0 0 $spacing-base;
    width: 24px;
    height: 25px;
  }

  .uhc-filter-group__content {
    padding: $spacing-base 0;
  }
}
