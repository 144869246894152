.filter-button-list {
    min-width: 0;
    margin-bottom: 25px;
}

.filter-button__input {
    clip: rect(0 0 0 0); 
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap; 
    width: 1px;
}

.filter-button {
    font-size: 16px;
    font-weight: 500;
    padding: 7px 20px;
    border-radius: 22px;
    border: 1px solid #CBCCCD;
    cursor: pointer;
    transition: border-color 0.3s ease-in-out;
    color: #333;

    &:hover,
    &:focus {
        border-color: #333333;
        transition: border-color 0.3s ease-in-out;
    }

    &.isActive {
        display: flex;
        align-items: center;
        column-gap: 8px;
        padding: 7px 12px 7px 20px;
        background-color: #EDF3FB;
        border: 1px solid #EDF3FB;

        &:after {
            content: '';
            background-image: url(./filter-close.svg);
            background-size: cover;
            background-repeat: no-repeat;
            display: inline-block;
            width: 20px;
            height: 20px;
            text-align: center;
        }

        &:hover,
        &:focus {
            border-color: #333333;
        }
    }
} 

/* Filter Button Drop */

.filter-button-drop {
    appearance: none;
    display: flex;
    align-items: center;
    column-gap: 1px;
    font-size: 16px;
    font-weight: 700;
    padding: 7px 12px 7px 20px;
    border-radius: 22px;
    border: 1px solid #002677;
    background: transparent;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    color: #002677;

    span {
        pointer-events: none;
    }

    &:hover,
    &:focus {
        box-shadow: 0 0 0 1px #002677;
        transition: all 0.3s ease-in-out;
    }

    &:after {
        content: '';
        display: inline-block;
        width: 24px;
        height: 24px;
        background-image: url(./filter-drop-arrow.svg);
        background-size: cover;
        background-repeat: no-repeat;
    }

    &.isActive {
        box-shadow: 0 0 0 1px #002677;
    }

    &.isFocused { 
        box-shadow: 0 0 0 1px #002677;

        &:after {
            transform: rotate(180deg);
        }
    }

    &:has(.filter-button-drop__plus) {
        background-color: #EDF3FB;
        box-shadow: 0 0 0 1px #002677;
    }
}