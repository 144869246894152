@import 'node_modules/@bswing/uhc-core/dist/scss/mixins';
@import 'node_modules/@bswing/uhc-core/dist/scss/variables';

.uhc-layered-tile {
  position: relative;

  .uhc-tile {
    display: none;

    @include media-query {
      display: block;
    }
  }

  &.uhc-layered-tile--top-left {
    .uhc-layered-tile__inner {
      top: -30px;
      left: 30px;
    }
  }

  &.uhc-layered-tile--top-right {
    .uhc-layered-tile__inner {
      top: -30px;
      right: 30px;
    }
  }

  &.uhc-layered-tile--bottom-left {
    .uhc-layered-tile__inner {
      bottom: -30px;
      left: 30px;
    }
  }

  &.uhc-layered-tile--bottom-right {
    .uhc-layered-tile__inner {
      bottom: -30px;
      right: 30px;
    }
  }

  .uhc-layered-tile__inner {
    @include media-query {
      position: absolute;
      width: 285px;
      height: auto;
    }
  }
}
