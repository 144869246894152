@import 'node_modules/@bswing/uhc-core/dist/scss/mixins';
@import 'node_modules/@bswing/uhc-core/dist/scss/variables';

.uhc-control-button {
  appearance: none;
  background: #fff;
  border: none;
  border-radius: 100%;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 1);
  color: $color-blue-secondary;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;

  @include media-query {
    width: 50px;
    height: 50px;
  }

  &:disabled {
    appearance: none;
    color: $color-gray-6;
    cursor: auto;
    opacity: 1;
  }
}
