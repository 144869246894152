@import 'node_modules/@bswing/uhc-core/dist/scss/mixins';
@import 'node_modules/@bswing/uhc-core/dist/scss/variables';

.uhc-zcp {
  position: relative;
  display: inline-block;

  .uhc-zcp__text[role='tooltip'] {
    animation-name: float;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    background: $color-gray-8;
    filter: drop-shadow(rgba(0, 0, 0, 0.25) 0 1px 2px);
    border-radius: 4px;
    color: $color-white;
    display: flex;
    align-items: flex-start;
    font-size: 16px;
    max-width: calc(100vw - 80px);
    padding: $spacing-base;
    position: absolute;
    top: calc(100% + 10px);
    left: 50%;
    bottom: auto;
    margin-left: -50%;
    width: 250px;
    z-index: 1;

    &::after {
      background: $color-gray-8;
      content: '';
      display: block;
      position: absolute;
      height: 14px;
      width: 14px;
      top: -7px;
      bottom: auto;
      left: calc(50% - 7px);
      transform: rotate(45deg);
    }

    @include media-query {
      left: 50%;
      max-width: 350px;
      margin-left: -60px;
      width: 350px;

      &::after {
        left: 50px;
      }
    }
  }

  &.uhc-zcp--paused .uhc-zcp__text[role='tooltip'] {
    animation-play-state: paused;
  }

  .uhc-zcp__text[role='tooltip'] a {
    color: $color-white;
    font-weight: bold;
  }

  .uhc-zcp__text[role='tooltip'] .uhc-zcp__close {
    color: $color-white;
    display: block;
    font-size: 30px;
    line-height: 0.5;
    margin-left: $spacing-small;

    &:hover {
      text-decoration: none;
    }

    &:focus {
      outline-offset: 4px;
    }
  }
}

@keyframes float {
  0% {
    transform: translateY(0%);
  }
  50% {
    transform: translateY(8%);
  }
  100% {
    transform: translateY(0%);
  }
}
