@import 'node_modules/@bswing/uhc-core/dist/scss/mixins';
@import 'node_modules/@bswing/uhc-core/dist/scss/variables';

.uhc-ms-plan-card {
  background: $color-white;
  border-radius: 8px;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.25);
  overflow: visible;

  .uhc-ms-plan-card__header {
    background: $color-blue-primary;
    border-radius: 8px 8px 0 0;
    color: $color-white;
    padding: 20px;
  }

  .uhc-ms-plan-card__main {
    height: 150px;
    overflow: hidden;
    padding: 20px;
    position: relative;

    .uhc-ms-plan-card__benefits {
      display: grid;
      grid-template-columns: 100%;
      grid-template-rows: 1fr;
      gap: 20px;
      border: $border-base;
      border-radius: 6px;
      margin: 0;
      padding: 20px;

      @include media-query {
        grid-template-columns: 40% 60%;
      }
    }
  }

  .uhc-ms-plan-card__footer {
    background: $color-white;
    border-top: $border-base;
    border-radius: 0 0 8px 8px;
    padding: 20px;
    position: relative;
    z-index: 1;

    &::before {
      background-image: linear-gradient(
        to top,
        rgba(0, 0, 0, 0.4) 0%,
        rgba(0, 0, 0, 0.2) 40%,
        rgba(0, 0, 0, 0) 100%
      );
      content: '';
      display: block;
      height: 30px;
      pointer-events: none;
      position: absolute;
      top: -31px;
      left: 0;
      right: 0;
      z-index: 0;
    }
  }

  .uhc-plan-card__expand-button {
    appearance: none;
    background: $color-white;
    border: $border-base;
    border-radius: 9999px;
    color: $color-blue-secondary;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    position: absolute;
    top: -21px;
    left: 50%;
    margin-left: -105px;
    height: 42px;
    width: 210px;

    &:focus {
      outline: 3px solid $color-blue-secondary;
    }
  }

  // Expanded State
  &.uhc-ms-plan-card--expanded {
    .uhc-ms-plan-card__main {
      height: auto;
      overflow: visible;
    }

    .uhc-ms-plan-card__footer {
      height: auto;

      &::before {
        display: none;
      }
    }
  }

  .uhc-ms-plan-card__legal {
    background: $color-gray-2;
    border-radius: 0 0 8px 8px;
    margin-top: 20px;
    margin-left: -20px;
    margin-right: -20px;
    margin-bottom: -20px;
    padding: 20px;
  }
}
