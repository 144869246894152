@import 'node_modules/@bswing/uhc-core/dist/scss/mixins';
@import 'node_modules/@bswing/uhc-core/dist/scss/variables';
@import 'uhc-variables';

.uhc-sticky {
  transform: translate3d(0, -100%, 0);
  transition: transform 300ms ease-out;
}

.uhc-sticky-enter-active {
  transform: translate3d(0, 0, 0);
  transition: transform 300ms ease-out;
}

.uhc-sticky-enter-done {
  transform: translate3d(0, 0, 0);
  z-index: 2;
}

.uhc-sticky-exit {
  transform: translate3d(0, 0, 0);
}

.uhc-sticky-exit-active {
  transform: translate3d(0, -100%, 0);
  transition: transform 300ms ease-out;
}

.uhc-sticky-exit-done {
  transform: translate3d(0, -100%, 0);
  z-index: 1;
}

.uhc-sup {
  font-size: 12px;
  font-weight: bold;
  margin-right: 2px;
  vertical-align: text-top;

  @include media-query {
    font-size: 16px;
  }
}

.uhc-popover {
  background-color: $color-white;
  border-radius: 5px;
  box-shadow: $box-shadow-base;
  padding: $spacing-base;
  z-index: 30;
  min-width: fit-content;
  width: 300px;
}

.uhc-zip-banner {
  background-size: 0 0;
  padding: 40px 0;

  @include media-query {
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: 305px 328px;
    padding: 140px 0;
  }
}

.uhc-zip-banner__heading {
  @include media-query {
    max-width: 60%;
  }
}

.uhc-vpp-alert {
  background: #fef9eb;
  border: 2px solid #f5b700;
  padding: 20px;
}

.uhc-agent-alert {
  background: #fcf0f0;
  border: 1px solid #c40000;
  font-weight: bold;
  padding: 10px 20px;
  text-align: center;
}

.uhc-accent {
  &:before {
    content: '';
    background: $color-gold-primary;
    display: block;
    height: 6px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
}

.rounded {
  border-radius: 4px;
}

.rounded-md {
  border-radius: 8px;
}

.rounded-lg {
  border-radius: 16px;
}

.rounded-xl {
  border-radius: 20px;
}

.underline {
  text-decoration: underline;
}

.no-underline {
  text-decoration: none;
}

.uhc-vpp-scroll-pane {
  max-height: 450px;
  overflow-y: auto;

  @include media-query {
    max-height: 290px;
  }
}

.uhc-sort-button {
  appearance: none;
  background: $color-white;
  border: 1px solid $color-gray-8;
  border-radius: 4px;
  cursor: pointer;
  padding: 15px;
}

.focus-teal {
  &:focus {
    outline: none;
  }

  &:focus-visible {
    outline: 3px solid $color-teal-primary;
  }
}

@media (min-width: 1000px) {
  .custom-row {
    display: grid;
    grid-template-columns:  25% 75%;
  }

  .custom-column-left {
    display: block !important;

  }

  // .custom-column-right {
  //   width: 100%;
  //   max-width: 75%;
  // }
}

.container {
  position: static;
}
