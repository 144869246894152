@import 'node_modules/@bswing/uhc-core/dist/scss/mixins';
@import 'node_modules/@bswing/uhc-core/dist/scss/variables';

.uhc-ribbon {
  background: $color-orange-primary;
  border-radius: 8px 0 0 8px;
  clip-path: polygon(0 0, 100% 0, calc(100% - 15px) 100%, 0% 100%);
  color: $color-white;
  font-weight: bold;
  min-width: fit-content;
  padding: 10px 20px;
  position: relative;
  margin-bottom: 10px;
  margin-left: -30px;
  white-space: nowrap;
  width: 75%;
}
