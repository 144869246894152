.sam {
    position: fixed;
    bottom: 15%;
    right: 5px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    z-index: 30;
}

.samOne {
    display: flex;
    align-items: center;
    column-gap: 11px;
    background-color: #002677;
    border-radius: 24px;
    outline: 1px solid white;

    & > * {
        padding-block: 7px !important;

        &:first-child {
            padding-left: 11px;
        }

        &:last-child {
            padding-right: 11px;
        }
    }
    

    &.samOne--chat.isOpen {
        width: 154px;
        transition: width 0.5s;
    }

    &.samOne--phone.isOpen {
        width: 197px;
        transition: width 0.5s;
    }

    button {
        appearance: none;
        border: none;
        background: transparent;
        padding: 0;
        cursor: pointer;
    }

    & + .samOne {
        margin-top: 10px;
    }

    &:hover,
    &:focus {
        background-color: #196ecf;
        transition: background-color 0.3s;
    }

    .samOne__modal {
        width: 100%;
    }

    .samOne__text {
        position: absolute;
        right: 41px;
        white-space: nowrap;
        transition: opacity 0.5s ease-in-out, right 0.4s;
    }

    &.isClosed {
        width: 77px;
        column-gap: 0;
        transition: width 0.5s;

        .samOne__text {
            right: -100%;
            opacity: 0;
            pointer-events: none;
            transition:  opacity 0.3s ease-in-out, right 0.5s ease-in-out;
        }

        .samOne__toggle {
            transform: scaleX(-1);
            transition: transform 0.5s;
        }

        & > * {
            &:first-child {
                padding-left: 11px;
                padding-right: 11px;
            }
        }
    }

    .samOne__toggle {
        transition: transform 0.5s;
    
        svg {
            margin-bottom: -3px;
        }
    }
    
    .samOne__modal {
        display: flex;
        position: relative;
        align-items: center;
        justify-content: flex-end;
        column-gap: 11px;
    }
    
    .samOne__text {
        opacity: 1;
        font-size: 16px;
        font-weight: 600;
        color: #fff;
        transition: all 0.3s;
    }
    
    &.samOne--chat .samOne__modal svg {
            margin-bottom: -5px;
    }
    
    &.samOne--phone .samOne__modal svg {
        margin-top: 2px;
    }
    
    &.samOne--phone .samOne__text {
        text-decoration: underline;
    }
}



// SAM overlay
.sam .uhc-overlay.uhc-overlay--centered {
    align-items: flex-start;
    margin-top: 30px;
}

.sam .chat-modal {
    display: none;
    position: fixed;
    bottom: 0;
    z-index: 99;
    box-shadow: 7px 9px 14px -3px rgba(0,0,0,0.32);

    &.isOpen {
        display: block;
    }
}

.samTwo {
    display: flex;
    align-items: center;
    column-gap: 11px;
    background-color: #00226C;
    outline: 1px solid white;
    border-radius: 24px;
    height: 42px;

    & > * {
        padding-block: 7px !important;

        &:first-child {
            padding-left: 11px;
        }

        &:last-child {
            padding-right: 11px;
        }
    }
    

    &.samTwo--chat.isOpen {
        width: 119px;
        transition: width 0.5s;
    }

    &.samTwo--phone.isOpen {
        width: 162px;
        transition: width 0.5s;
    }

    button {
        appearance: none;
        border: none;
        background: transparent;
        padding: 0;
        cursor: pointer;
    }

    & + .samTwo {
        margin-top: 10px;
    }

    &:hover,
    &:focus {
        background-color: #196ecf;
        transition: background-color 0.3s;
    }

    .samTwo__modal {
        width: 100%;
    }

    .samTwo__text {
        position: absolute;
        right: 41px;
        white-space: nowrap;
        transition: opacity 0.5s ease-in-out, right 0.4s;
    }

    &.isClosed {
        width: 42px;
        column-gap: 0;
        transition: width 0.5s;

        .samTwo__text {
            right: -100%;
            opacity: 0;
            pointer-events: none;
            transition:  opacity 0.3s ease-in-out, right 0.5s ease-in-out;
        }

        .samTwo__toggle {
            transform: scaleX(-1);
            transition: transform 0.5s;
        }

        & > * {
            &:first-child {
                padding-left: 11px;
                padding-right: 11px;
            }
        }

        &:hover {
            column-gap: 11px;
            transition: width 0.5s;

            &.samTwo--chat {
                width: 119px;
                transition: width 0.5s;
            }
        
            &.samTwo--phone {
                width: 162px;
                transition: width 0.5s;
            }

            .samTwo__text {
                right: 41px;
                opacity: 1;
                pointer-events: default;
                transition: opacity 0.5s ease-in-out, right 0.4s;
            }

            .samTwo__toggle {
                transform: scaleX(0);
                transition: transform 0.5s;
            }
        }
    }

    .samTwo__toggle {
        transition: transform 0.5s;
    
        svg {
            margin-bottom: -3px;
        }
    }
    
    .samTwo__modal {
        display: flex;
        position: relative;
        align-items: center;
        justify-content: flex-end;
        column-gap: 11px;
    }
    
    .samTwo__text {
        opacity: 1;
        font-size: 16px;
        font-weight: 600;
        color: #fff;
        transition: all 0.3s;
    }
    
    &.samTwo--chat .samTwo__modal svg {
        margin-bottom: -5px;
    }
    
    &.samTwo--phone .samTwo__modal svg {
        margin-top: 4px;
    }
    
    &.samTwo--phone .samTwo__text {
        text-decoration: underline;
    }
}

.sam {
    .abyss-dropdown-menu-trigger {
        width: 133px;

        &[aria-expanded="true"] {
            margin-right: 15px;
        }
    }
}

.abyss-dropdown-menu-content {
    min-width: 0 !important;
    width: 151px !important;
    padding: 8px 3px 8px 8px !important;

    .abyss-dropdown-menu-item {
        font-size: 14px;
        padding-left: 26px !important;
    }

    ul {
        padding: 0;
    }

    li + li {
        margin-top: 8px;
    }

    li:last-child {
        text-decoration: underline;
    }
}

.samThree__drop {
    position: relative;
}

.samDrop__button {
    display: flex;
    flex-direction: row;
    column-gap: 6px;
    border-radius: 4px;
    border: 1px solid #196ECF;
    background: #E5F8FB;
    padding: 9px 33px;
    font-weight: 600;
    font-size: 14px;
    width: 151px;
    color: #196ECF;
    cursor: pointer;
    transition: all 0.3s;

    &:hover,
    &:focus {
        background: #196ECF;
        color: #fff;
        transition: all 0.3s;

        svg path {
            fill: #fff;
        }
    }

    .isClosed & {
        background: #196ECF;
        color: #fff;

        svg path {
            fill: #fff;
        }
    }
}

.samDrop__menu {
    position: absolute;
    top: 46px;
    width: 151px;
    background: #fff;
    padding: 8px;
    border: 1px solid #E5E5E6;
    box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.15);

    .isClosed & {
        display: none;
    }

    button {
        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: 10px;
        appearance: none;
        background: transparent;
        border: none;
        font-size: 14px;
        cursor: pointer;

        span:first-child {
            width: 18px;
            height: 18px;
        }

        &:last-child {
            text-decoration: underline;
        }

        & + button {
            margin-top: 8px;
        }
    }
}

.sam.samai {
    z-index: 8;
    .samTwo {
        background: #8C195E;
    }

    .samTwo--ai.isOpen,
    .samTwo.isClosed:hover.samTwo--ai {
        width: 220px;
        transition: width 0.5s;
    }
}

[data-radix-popper-content-wrapper] {
    z-index: 99999 !important;
}
