.swiper {
    padding-block: 5px;
    margin-left: 0;
    position: relative;
    width: 100%;
}

.swiper-wrapper {
    position: static !important;
    padding-left: 1px;
}

.swiper-slide { 
    width: fit-content !important;
}

.swiper-button-next,
.swiper-button-prev {
    display: none;
    width: 40px;
    height: 40px;
    border-radius: 4px;
    border: 1px solid #CBCCCD;
    background: #fff;
    box-shadow: 0px 1px 7px 2px rgba(0, 0, 0, 0.12);

    &:after {
        font-size: 0;
        width: 20px;
        height: 20px;
        background-image: url('./arrow.svg');
        background-repeat: no-repeat;
    }

    &.swiper-button-disabled {
        opacity: 0;
    }

    @media only screen and (min-width: 767px) {
        display: flex;
    }
}

.swiper-button-prev {
    left: 0;
}

.swiper-button-next {
    right: 0;
}

.swiper-button-prev:after {
    transform: rotate(180deg);
}