.filter-contain {
    position: relative;
    display: flex;
    justify-content: space-between;
    column-gap: 5px;
    align-items: center;
}

.filters-clear {
    appearance: none;
    background: transparent;
    border: none;
    white-space: nowrap;
    position: absolute;
    top: -26px;
    right: 0;

    @media screen and (min-width: 767px) {
        position: static;
        top: auto;
        right: auto;
    }
}