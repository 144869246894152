.checkbox {
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: 8px;
  user-select: none;
  -webkit-tap-highlight-color: transparent;

  .checkbox__input {
    clip: rect(0 0 0 0); 
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap; 
    width: 1px;
  }

  .checkbox__label {
    display: block;
    font-size: 16px;
    font-weight: 500;
  }

  .checkbox__visual {
    align-items: center;
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    width: 26px;
    height: 26px;
    background-color: #fff;
    border: 1px solid #7D7F81;
    border-radius: 2px;
  }
}

.checkbox + .checkbox {
    margin-top: 16px;
}

.checkbox .checkbox__input:checked ~ .checkbox__visual {
    background-color: #196ECF;
    background-size: 18px;
    background-image: url('./checkbox-check.svg');
    background-repeat: no-repeat;
    background-position: center;
}
