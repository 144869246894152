@import 'node_modules/@bswing/uhc-core/dist/scss/mixins';
@import 'node_modules/@bswing/uhc-core/dist/scss/variables';

.uhc-slide-table__container {
  overflow: hidden;
  position: relative;

  &:after {
    content: '';
    border: $border-base;
    display: block;
    pointer-events: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
  }

  &.uhc-slide-table__container--preview {
    width: calc(100% + 80px);

    &:before {
      background-image: linear-gradient(
        to right,
        rgba(255, 255, 255, 0) -29%,
        rgba(255, 255, 255, 0.26) -7%,
        rgba(255, 255, 255, 0.56) 12%,
        rgba(255, 255, 255, 0.79) 33%,
        rgba(255, 255, 255, 0.94) 53%,
        $color-white 79%
      );
      content: '';
      display: block;
      position: absolute;
      top: -1px;
      right: 0;
      bottom: -1px;
      z-index: 3;
      width: 80px;
    }

    &:after {
      content: '';
      border-top: $border-base;
      border-bottom: $border-base;
      border-left: $border-base;

      display: block;
      pointer-events: none;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 2;
    }
  }

  &.uhc-slide-table__container--rounded,
  &.uhc-slide-table__container--rounded:after {
    @include media-query {
      border-radius: 8px;
    }
  }
}

.uhc-slide-table {
  background: $color-white;
  border: none;
  border-collapse: collapse;
  border-spacing: 0px;
  height: 1px; // REF: https://stackoverflow.com/a/46110096/961416
  padding: 0;
  position: relative;
  table-layout: fixed;

  .uhc-slide-table__row {
    border-top: $border-base;
  }

  .hl-item {
    padding: 3px 8px 4px;

    sup {
      font-size: 10px;
      top: -1em;
      left: 3px;
    }
  }

  .uhc-slide-table__cell {
    background: $color-white;
    background-clip: padding-box;
    border: none;
    box-sizing: border-box;
    font-weight: 500;
    height: 100%;
    line-height: normal;
    margin: 0;
    overflow: hidden;
    padding: 15px;
    position: relative;
    text-align: center;
    transition: transform 150ms ease-out;
    white-space: normal;
    vertical-align: top;
    backface-visibility: hidden;
    perspective: 1000;
    -webkit-perspective: 1000;
    width: auto;

    &:after {
      background: $color-gray-3;
      content: '';
      display: block;
      width: 1px;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
    }

    &:before {
      background: $color-gray-3;
      content: '';
      display: block;
      width: 1px;
      position: absolute;
      top: 0;
      left: -1px;
      bottom: 0;
    }

    @include media-query {
      text-align: left;
    }

    &[data-direction='right'] {
      transition-delay: 0ms, 150ms;
    }

    &.uhc-slide-table__cell--hcentered {
      text-align: center;
    }

    &.uhc-slide-table__cell--vcentered {
      vertical-align: middle;
    }

    &.uhc-slide-table__cell--frozen {
      display: none;
      z-index: 2;

      @include media-query {
        display: table-cell;
        font-size: 17px;
        text-align: left;
      }
    }

    &.uhc-slide-table__cell--frozen-desktop {
      z-index: 1;
    }

    &.uhc-slide-table__cell--frozen:first-child {
      &:before {
        left: 0;
      }
    }

    &.uhc-slide-table__cell--mobile-heading {
      background: $color-gray-1;
      background-clip: padding-box;
      border-top: $border-base;
      border-bottom: $border-base;
      display: table-cell;
      font-size: 17px;
      font-weight: 700;
      padding: 10px 15px;
      text-align: left;
      cursor: pointer;

      .mobile-heading-contain {
        display: grid;
        grid-template-columns: auto 30px;
        align-items: flex-start;
      }

      .mobile-heading-arrow {
        width: 12px;
        height: 8px;
        position: relative;
        top: 10px;
      }

      &.is-active .mobile-heading-arrow {
        transform: rotate(180deg);
      }

      .mobile-heading-description {
        display: none;
        text-align: left;
        padding-right: 30px;
      }

      &.is-active {
        .mobile-heading-description {
          display: block;
        }
      }

      @include media-query {
        display: none;
      }
    }
  }

  td.uhc-slide-table__cell {
    padding: 32px 16px;
    text-align: center;

    @include media-query {
      padding: 32px 22px;
    }
  }

  .uhc-slide-table__row {
    &:first-child {
      .uhc-slide-table__cell.uhc-slide-table__cell--mobile-heading {
        border-top: none;
      }
    }
  }
}
